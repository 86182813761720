import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/vsts/work/1/s/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Navigation } from "../../src/components/Navigation";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "what-is-erp"
    }}>{`What is ERP?`}</h1>
    <p>{`Most businesses, big and small, generally use an Enterprise Resource Planning (ERP) system.
Some of its uses are:`}</p>
    <ul>
      <li parentName="ul">{`Keeping track of all transactions and ensuring that the accounting is correct 🧾`}</li>
      <li parentName="ul">{`Keeping track of customers, their orders and payments 🧍`}</li>
      <li parentName="ul">{`Keeping track of inventory and order new products when they run low 🚚`}</li>
      <li parentName="ul">{`...and a lot more...`}</li>
    </ul>
    <p>{`As you can see, its mostly about `}<em parentName="p">{`keeping track`}</em>{` of everything the business needs to run. 🛤️`}</p>
    <h2 {...{
      "id": "reporting"
    }}>{`Reporting`}</h2>
    <p>{`But notice, since they now got all this information stored in a single place, it's very easy to create PDF and Excel files with, for instance:`}</p>
    <ul>
      <li parentName="ul">{`Top 10 customers this month 📈`}</li>
      <li parentName="ul">{`Top 5 products by region (Bergen, Oslo, ...) 📊`}</li>
      <li parentName="ul">{`Top 3 products that have the highest return rate 📉`}</li>
    </ul>
    <p>{`All of this information is incredibly useful when making decisions about what the business should be focusing on going forward.
Presenting the data in this way is called `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Business_intelligence"
      }}>{`Business Intelligence`}</a>{`.`}</p>
    <h2 {...{
      "id": "integration"
    }}>{`Integration`}</h2>
    <p>{`Remember that I said you could create PDF and Excel files? Well those are just two ways to present the data:`}</p>
    <ul>
      <li parentName="ul">{`PDF is excellent for presentations and human interpretation 📑`}</li>
      <li parentName="ul">{`Excel is great for management who usually knows how to use Excel and can process that data further on their own 🧮`}</li>
    </ul>
    <p>{`If you take that data and put it in a very structured format, i.e. `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/JSON"
      }}>{`JSON`}</a>{` or
`}<a parentName="p" {...{
        "href": "https://no.wikipedia.org/wiki/XML"
      }}>{`XML`}</a>{`,
it's very `}<em parentName="p">{`easy`}</em>{` for another system to read that information back out and understand the contents `}{`—`}{` which
would be very hard to do with a PDF file. 😏`}</p>
    <h3 {...{
      "id": "what-would-i-use-it-for"
    }}>{`What would I use it for?`}</h3>
    <p>{`Now, imagine we had a way to send files to the government and they somehow understood our XML files,
then we could automatically report tax and employee information without having to spend an insane amount of time
filling those out by hand. 🥱`}</p>
    <p>{`Well, this actually exists! `}<a parentName="p" {...{
        "href": "https://docs.altinn.studio/"
      }}>{`Altinn Studio`}</a>{` is a website dedicated to teach you how
to communicate with `}<a parentName="p" {...{
        "href": "https://www.altinn.no/"
      }}>{`Altinn`}</a>{` automatically. 🤯`}</p>
    <p>{`Communicating with other systems like this is called `}<a parentName="p" {...{
        "href": "https://www.gend.co/blog/what-is-api-integration-a-guide-for-non-technical-people"
      }}>{`Integration`}</a>{`.`}</p>
    <p>{`Exactly how you'd do something like this is beyond the scope of this introduction; the point I want to make is
that having structured data in an ERP system comes with a lot of benefits, which you wouldn't necessarily get by
only using Excel sheets.`}<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup></p>
    <Navigation previous="/" next="/introduction/business-central" mdxType="Navigation" />
    <br />

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>{`...yes, there's a lot of businesses that run purely on big, complex Excel sheets... 🥵.`}<a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      